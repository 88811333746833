<template>
    <vx-card :title="title">
        <vs-tabs v-if="render" v-model="activeTab">
            <vs-tab label="New">
                <New></New>
            </vs-tab>
            <vs-tab label="Waiting">
                <Waiting></Waiting>
            </vs-tab>
            <vs-tab label="Inquiry">
                <Inquiry></Inquiry>
            </vs-tab>
            <vs-tab label="Approved">
                <Approved></Approved>
            </vs-tab>
            <vs-tab label="Rejected">
                <Rejected></Rejected>
            </vs-tab>
            <vs-tab label="Executed">
                <Executed></Executed>
            </vs-tab>
            <vs-tab label="Canceled">
                <Canceled></Canceled>
            </vs-tab>
        </vs-tabs>
    </vx-card>
</template>
<script>
import New from "./_tab/tab-new.vue";
import Waiting from "./_tab/tab-waiting.vue";
import Inquiry from "./_tab/tab-inquiry.vue";
import Approved from "./_tab/tab-approved.vue";
import Rejected from "./_tab/tab-rejected.vue";
import Executed from "./_tab/tab-executed.vue";
import Canceled from "./_tab/tab-canceled.vue";
export default {
    components: {
        New,
        Waiting,
        Inquiry,
        Approved,
        Rejected,
        Executed,
        Canceled,
    },
    data() {
        return {
            title: "Inter Storage",
            tabStatus: 0,
            activeTab: 0,
            render: true,
        };
    },
    mounted() {
        this.tabStatus = this.$route.params.status;

        if (this.tabStatus === 3) {
            this.activeTab = 1
            this.render = false
            this.$nextTick(() => this.render = true)
        } else if (this.tabStatus === 7) {
            this.activeTab = 2
            this.render = false
            this.$nextTick(() => this.render = true)
        } else if (this.tabStatus === 4) {
            this.activeTab = 3
            this.render = false
            this.$nextTick(() => this.render = true)
        } else if (this.tabStatus === 6) {
            this.activeTab = 4
            this.render = false
            this.$nextTick(() => this.render = true)
        } else if (this.tabStatus === 5) {
            this.activeTab = 5
            this.render = false
            this.$nextTick(() => this.render = true)
        } else if (this.tabStatus === 9) {
            this.activeTab = 6
            this.render = false
            this.$nextTick(() => this.render = true)
        }
    },
};
</script>
